<template>
  <el-card shadow="never" style="height: 100%">
    <div slot="header" class="clearfix">
      <el-form
          id="change"
          status-icon
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
      >
        所属客户：
        <el-select v-model="customerId" placeholder="请选择">
          <el-option
              v-for="item in customerOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
        周：<el-date-picker
          :picker-options="pickerOptions"
          @change="disabledEditw()"
          v-model="week"
          type="week"
          format="yyyy 第 WW 周"
          placeholder="选择周">
      </el-date-picker>
        月：<el-date-picker
          :picker-options="pickerOptions"
          @change="disabledEditm()"
          v-model="month"
          type="month"
          placeholder="选择月"
          style="margin-right: 10px">
      </el-date-picker>
        <el-button
            type="primary"
            @click="cgetYouleList()"
            style="background: #1d90ff; border: none"
        >查询</el-button
        >

      </el-form>
    </div>
    <el-table
        :data="tableData"
        border
        @sort-change="changeTableSort"
        :default-sort="{ prop: 'amount', order: 'descending' }"
    >
      <el-table-column
          prop="commodityId"
          label="商品ID"
          width="150"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="commodityName"
          label="商品名称"
          width="250"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="visitorCountSum"
          sortable="custom"
          label="商品访客数"
          width="130"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="viewsSum"
          sortable="custom"
          label="商品浏览量"
          width="115"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="avgTimeSum"
          sortable="custom"
          label="平均停留时长"
          width="130"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="missRateAvg"
          label="详情页跳出率"
          sortable="custom"
          width="130"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="collCountSum"
          sortable="custom"
          label="商品收藏人数"
          width="130"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="addCountSum"
          sortable="custom"
          label="商品加购件数"
          width="130"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="buyerCountSum"
          sortable="custom"
          label="下单买家数"
          width="130"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="orderCountSum"
          sortable="custom"
          label="下单件数"
          align="center"
          width="115"
      >
      </el-table-column>
      <el-table-column
          prop="orderAmountSum"
          width="110"
          sortable="custom"
          label="下单金额"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="orderConRateAvg"
          width="130"
          sortable="custom"
          label="下单转化率"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="payBuyerCountSum"
          label="支付买家数"
          width="130"
          sortable="custom"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="payCountSum"
          width="110"
          label="支付件数"
          sortable="custom"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="payAmountSum"
          sortable="custom"
          width="110"
          label="支付金额"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="payRateAvg"
          sortable="custom"
          width="130"
          label="支付转化率"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="visitorValueSum"
          sortable="custom"
          width="130"
          label="访客平均价值"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="refundAmountSum"
          sortable="custom"
          width="190"
          label="售中售后成功退款金额"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="searchPayRateAvg"
          sortable="custom"
          width="180"
          label="搜索引导支付转化率"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="searchVisitorCountSum"
          sortable="custom"
          width="150"
          label="搜索引导访客数"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="searchBuyerCountSum"
          sortable="custom"
          width="180"
          label="搜索引导支付买家数"
          align="center"
      >
      </el-table-column>
    </el-table>
    <div style="text-align: center;margin-top: 20px">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage4"
          :page-sizes="[10, 20, 50, 60]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagetotal"
      >
      </el-pagination>
    </div>
  </el-card>
</template>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
<script>
import $ from "jquery";

export default {
  methods: {
    disabledEditw(){
      if(this.week !=null){
        this.month = null

      }
      
      
    },
    disabledEditm(){
     if(this.month !=null){
        this.week = null

      }
      
    },
    getCustomerList() {
      this.$api.get(
          "/customer/list",
          null,
          {
            list: false
          },
          (successRes) => {
            this.customerOptions = successRes.data.rows;
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
    cgetYouleList(page, record) {
      this.getTimes();
      console.log(this.startTime);
        this.$api.get(
        "/taobao/cc/list",
        null,
        {
          
          custId:this.customerId,
          startTime:this.startTime,
           endTime:this.endTime,
          visitorCount: this.visitorCountSum,
          views: this.viewsSum,
          avgTime: this.avgTimeSum,
          missRate: this.missRateAvg,
          collCount: this.collCountSum,
          addCount: this.addCountSum,
          buyerCount: this.buyerCountSum,
          orderCount: this.orderCountSum,
          orderAmount: this.orderAmountSum,
          orderConRate:this.orderConRateAvg,
          payBuyerCount:this.payBuyerCountSum,
          payCount:this.payCountSum,
          payAmount:this.payAmountSum,
          payRate:this.payRateAvg,
          visitorValue:this.visitorValueSum,
          refundAmount:this.refundAmountSum,
          searchPayRate:this.searchPayRateAvg,
          searchVisitorCount:this.searchVisitorCountSum,
          searchBuyerCount:this.searchBuyerCountSum,
        },
        (successRes) => {
          

          //日期改掉关键字
          var exf = successRes.data.rows;
          console.log(exf);
            for (var i = 0; i< exf.length;i++){
              let missRateAvg = (exf[i].missRateAvg*100).toFixed(4);
              exf[i].missRateAvg = parseFloat(missRateAvg).toFixed(2)+"%";
              let searchPayRateAvg = (exf[i].searchPayRateAvg*100).toFixed(4);
              exf[i].searchPayRateAvg = parseFloat(searchPayRateAvg).toFixed(2)+"%";
               let orderConRateAvg = (exf[i].orderConRateAvg*100).toFixed(4);
              exf[i].orderConRateAvg = parseFloat(orderConRateAvg).toFixed(2)+"%";
              let payRateAvg = (exf[i].payRateAvg*100).toFixed(4);
              exf[i].payRateAvg = parseFloat(payRateAvg).toFixed(2)+"%";
              
              
            }
            console.log(exf);
            
            this.tableData = exf;
          // this.tableData = successRes.data.rows;
          this.pagetotal = successRes.data.total;
        },
        (failureRes) => {
          console.log(failureRes);
        }
      );

      
      
    },



    getYouleList(page, record) {
      
      this.getTimes();
      
        this.$api.get(
        "/taobao/cc/list",
        null,
        {
          page: page === 1 ? 1 : this.page,
          record: record === 10 ? 10 : this.record,
          custId:this.customerId,
          startTime:this.startTime,
           endTime:this.endTime,
          visitorCount: this.visitorCountSum,
          views: this.viewsSum,
          avgTime: this.avgTimeSum,
          missRate: this.missRateAvg,
          collCount: this.collCountSum,
          addCount: this.addCountSum,
          buyerCount: this.buyerCountSum,
          orderCount: this.orderCountSum,
          orderAmount: this.orderAmountSum,
          orderConRate:this.orderConRateAvg,
          payBuyerCount:this.payBuyerCountSum,
          payCount:this.payCountSum,
          payAmount:this.payAmountSum,
          payRate:this.payRateAvg,
          visitorValue:this.visitorValueSum,
          refundAmount:this.refundAmountSum,
          searchPayRate:this.searchPayRateAvg,
          searchVisitorCount:this.searchVisitorCountSum,
          searchBuyerCount:this.searchBuyerCountSum,
        },
        (successRes) => {
          //日期改掉关键字
          var exf = successRes.data.rows;
            for (var i = 0; i< exf.length;i++){
        
              let missRateAvg = (exf[i].missRateAvg*100).toFixed(4);
              exf[i].missRateAvg = parseFloat(missRateAvg).toFixed(2)+"%";
              let searchPayRateAvg = (exf[i].searchPayRateAvg*100).toFixed(4);
              exf[i].searchPayRateAvg = parseFloat(searchPayRateAvg).toFixed(2)+"%";
               let orderConRateAvg = (exf[i].orderConRateAvg*100).toFixed(4);
              exf[i].orderConRateAvg = parseFloat(orderConRateAvg).toFixed(2)+"%";
              let payRateAvg = (exf[i].payRateAvg*100).toFixed(4);
              exf[i].payRateAvg = parseFloat(payRateAvg).toFixed(2)+"%";
              
              
            }
            
            this.tableData = exf;
          // this.tableData = successRes.data.rows;
          this.pagetotal = successRes.data.total;
        },
        (failureRes) => {
          console.log(failureRes);
        }
      );

      
      
    },
    changeTableSort(column, prop, order) {
      (this.visitorCountSum = null),
        (this.viewsSum = null),
        (this.avgTimeSum = null),
        (this.missRateAvg = null),
        (this.collCountSum = null),
        (this.addCountSum = null),
        (this.buyerCountSum = null);
        (this.orderCountSum = null);
        (this.orderAmountSum = null);
        (this.orderConRateAvg = null);
        (this.payBuyerCountSum = null);
        (this.payCountSum = null);
        (this.payAmountSum = null);
        (this.payRateAvg = null);
        (this.visitorValueSum = null);
        (this.refundAmountSum = null);
        (this.searchPayRateAvg = null);
        (this.searchVisitorCountSum = null);
        (this.searchBuyerCountSum = null);
      if (column.prop === "visitorCountSum") {
        if (column.order === "ascending") {
          this.visitorCountSum = true;
        } else {
          this.visitorCountSum = false;
        }
      }
      if (column.prop === "viewsSum") {
        if (column.order === "ascending") {
          this.viewsSum = true;
        } else {
          this.viewsSum = false;
        }
      }
      if (column.prop === "avgTimeSum") {
        if (column.order === "ascending") {
          this.avgTimeSum = true;
        } else {
          this.avgTimeSum = false;
        }
      }
      if (column.prop === "missRateAvg") {
        if (column.order === "ascending") {
          this.missRateAvg = true;
        } else {
          this.missRateAvg = false;
        }
      }
      if (column.prop === "collCountSum") {
        if (column.order === "ascending") {
          this.collCountSum = true;
        } else {
          this.collCountSum = false;
        }
      }
      if (column.prop === "addCountSum") {
        if (column.order === "ascending") {
          this.addCountSum = true;
        } else {
          this.addCountSum = false;
        }
      }
      if (column.prop === "buyerCountSum") {
        if (column.order === "ascending") {
          this.buyerCountSum = true;
        } else {
          this.buyerCountSum = false;
        }
      }
      if (column.prop === "orderCountSum") {
        if (column.order === "ascending") {
          this.orderCountSum = true;
        } else {
          this.orderCountSum = false;
        }
      }
      if (column.prop === "orderAmountSum") {
        if (column.order === "ascending") {
          this.orderAmountSum = true;
        } else {
          this.orderAmountSum = false;
        }
      }
      if (column.prop === "orderConRateAvg") {
        if (column.order === "ascending") {
          this.orderConRateAvg = true;
        } else {
          this.orderConRateAvg = false;
        }
      }
      if (column.prop === "payBuyerCountSum") {
        if (column.order === "ascending") {
          this.payBuyerCountSum = true;
        } else {
          this.payBuyerCountSum = false;
        }
      }
      if (column.prop === "payCountSum") {
        if (column.order === "ascending") {
          this.payCountSum = true;
        } else {
          this.payCountSum = false;
        }
      }
      if (column.prop === "payAmountSum") {
        if (column.order === "ascending") {
          this.payAmountSum = true;
        } else {
          this.payAmountSum = false;
        }
      }
      if (column.prop === "payRateAvg") {
        if (column.order === "ascending") {
          this.payRateAvg = true;
        } else {
          this.payRateAvg = false;
        }
      }
      if (column.prop === "visitorValueSum") {
        if (column.order === "ascending") {
          this.visitorValueSum = true;
        } else {
          this.visitorValueSum = false;
        }
      }
      if (column.prop === "refundAmountSum") {
        if (column.order === "ascending") {
          this.refundAmountSum = true;
        } else {
          this.refundAmountSum = false;
        }
      }
      if (column.prop === "searchPayRateAvg") {
        if (column.order === "ascending") {
          this.searchPayRateAvg = true;
        } else {
          this.searchPayRateAvg = false;
        }
      }
      if (column.prop === "searchVisitorCountSum") {
        if (column.order === "ascending") {
          this.searchVisitorCountSum = true;
        } else {
          this.searchVisitorCountSum = false;
        }
      }
      if (column.prop === "searchBuyerCountSum") {
        if (column.order === "ascending") {
          this.searchBuyerCountSum = true;
        } else {
          this.searchBuyerCountSum = false;
        }
      }
      this.currentPage4 = 1;
      this.getYouleList(1, 10);

      console.log(column.prop); //prop标签 => nickname
      console.log(column.order); //descending降序、ascending升序
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },

    handleClick(row) {
      console.log(row);
    },
    handleSizeChange(val) {
      this.record = val;
      this.getYouleList(this.page, this.record);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getYouleList(this.page, this.record);
    },
    getTimes(){
       if(this.week == null && this.month == null){
        this.startTime = null
        this.endTime = null

      }
      if(this.week != null){
        const d = new Date(this.week- 1*24 * 3600 * 1000)
        const resDate = d.getFullYear() + '-' + this.p((d.getMonth() + 1)) + '-' + this.p(d.getDate())
         this.startTime = resDate;
         console.log(this.startTime)
        const res = new Date(+new Date(resDate) + 6*24 * 3600 * 1000)
         const f = new Date(res)
        const endTime = f.getFullYear() + '-' + this.p((f.getMonth() + 1)) + '-' + this.p(f.getDate())
        this.endTime = endTime;

        console.log("选择时间不为空")
        console.log(this.startTime)

      }
     
      
        if(this.month != null){
          const a = new Date(this.month)
        const resDatee = a.getFullYear() + '-' + this.p((a.getMonth() + 1)) + '-' + this.p(a.getDate())
         this.startTime = resDatee;
         let ress  = '' ;
         if(a.getMonth()+1==1 || a.getMonth()+1==3 || a.getMonth()+1==5 ||a.getMonth()+1==7 ||a.getMonth()+1==8 ||a.getMonth()+1==10||a.getMonth()+1==12){
            ress = new Date(+new Date(resDatee) + 30*24 * 3600 * 1000)

         }else if(a.getMonth()+1==2){
           if(((0 == a.getFullYear()%4)&&(0 != a.getFullYear()%100)) ||(0 == a.getFullYear() %400)){
             ress = new Date(+new Date(resDatee) + 28*24 * 3600 * 1000)
           }else{
             ress = new Date(+new Date(resDatee) + 27*24 * 3600 * 1000)
           }
         }else{
          ress = new Date(+new Date(resDatee) + 29*24 * 3600 * 1000)

         }
       
         const b = new Date(ress)
        const eendTime = b.getFullYear() + '-' + this.p((b.getMonth() + 1)) + '-' + this.p(b.getDate())
        this.endTime = eendTime;

        }


        
    },
     p(s) {
           return s < 10 ? '0' + s : s
            },
    

  },
  data() {
    return {
      pickerOptions: {
               disabledDate: time => {
                   return (
                      //  time < this.getTimes() ||
                       time > new Date()
                   )
               },
               firstDayOfWeek: 1,
           },
      customerOptions: [],
      customerId: null,
      week:null,
      month:null,
      page: 1,
      record: 10,
      visitorCountSum: null,
          viewsSum: null,
          avgTimeSum: null,
          missRateAvg: null,
          collCountSum: null,
          addCountSum: null,
          buyerCountSum: null,
          orderCountSum: null,
          orderAmountSum: null,
          orderConRateAvg:null,
          payBuyerCountSum:null,
          payCountSum:null,
          payAmountSum:null,
          payRateAvg:null,
          visitorValueSum:null,
          refundAmountSum:null,
          searchPayRateAvg:null,
          searchVisitorCountSum:null,
          searchBuyerCountSum:null,
      value: "",
      tableData: [],
      asearchdata: {},
      userInfo: {},
      updateStatus: 0,
      pagetotal: 0,
      spagetotal: 10,
      nowpagetotal: 1,
      currentPage4: 1,
      value1: null,
      value2: null,
    };
  },
  
  mounted() {
    this.getCustomerList();
  },
};
</script>